<template>
  <v-container fluid fill-height class="d-flex justify-center">
    <v-sheet elevation="6">
      <v-row>
        <!-- col 1  -->
        <v-col cols="12" md="6" class="hidden-sm-and-down">
          <div
            class="accent d-flex flex-column justify-sm-space-around fill-height"
          >
            <Language></Language>

            <div>
              <v-card-text class="white--text text-uppercase">
                <h3 class="text-center">
                  {{ $t('welcome') }} {{ $t('bonusManagementPortal') }}
                </h3>
              </v-card-text>
              <hr class="horizontal-underline" />
            </div>
            <div></div>
          </div>
        </v-col>

        <!-- col 2  -->
        <v-col cols="12" md="6">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-card-text class="d-flex flex-column justify-center">
              <h2 class="text-center accent--text text-capitalize">
                {{ $t('loginText') }}
              </h2>
              <!-- <i class="text-center">
                {{ $t('fillDetailsToAccessAccount') }}
              </i> -->

              <v-row align="center" justify="center">
                <v-col cols="12" sm="10">
                  <v-text-field
                    ref="email"
                    v-model="email"
                    required
                    prepend-inner-icon="mdi-account"
                    outlined
                    rounded
                    color="blue"
                    autocomplete="false"
                    class="mt-8"
                    dense
                    :label="$t('emailAdressLabel')"
                    :rules="emailRules"
                  />
                  <v-text-field
                    ref="password"
                    v-model="password"
                    required
                    rounded
                    outlined
                    color="blue"
                    autocomplete="false"
                    placeholder="Password"
                    prepend-inner-icon="mdi-key"
                    dense
                    :rules="passwordRules"
                    :label="$t('passwordInputFieldPlaceHolder')"
                    :type="passwordShow ? 'text' : 'password'"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="passwordShow = !passwordShow"
                  />

                  <div class="mainCheckBox">
                    <v-checkbox
                      v-model="checkbox"
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      required
                    >
                      <template v-slot:label>
                        <div>
                          {{ $t('acceptAll') }}
                          <v-tooltip>
                            <template v-slot:activator="{ on }">
                              <a
                                class="text-decoration-underline font-weight-bold"
                                target="_blank"
                                @click.stop
                                v-on="on"
                              >
                                {{ $t('termsAndConditions') }}
                              </a>
                            </template>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>

                  <v-btn
                    rounded
                    block
                    :disabled="!valid"
                    color="#536878"
                    type="submit"
                    class="white--text"
                    :loading="loading"
                  >
                    {{ $t('loginText') }}
                  </v-btn>

                  <div
                    class="mt-2 text-center text-decoration-underline font-weight-bold"
                  >
                    <router-link to="/forgot-password">
                      {{ $t('forgotPassword') }}
                    </router-link>
                  </div>

                  <div class="d-flex justify-space-between align-center my-5">
                    <hr class="or-line" />
                    <h5 class="text-center">{{ $t('or') }}</h5>
                    <hr class="or-line" />
                  </div>

                  <div class="d-flex justify-center my-5">
                    <a>
                      <v-img
                        src="../assets/ms-symbollockup_signin_light.svg"
                        class="btn"
                        max-width="215"
                        max-height="41"
                        @click="adSignIn()"
                      />
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-col>
        <!-- col 2 ends  -->
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import Language from '../../src/components/language.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'login',
  components: {
    Language,
  },

  data() {
    return {
      // form validation essentials
      checkbox: false,
      valid: false,
      passwordShow: false,
      users: null,

      email: '',
      emailRules: [
        (v) => !!v || this.$t('emailIputHint'),
        (v) => /.+@.+\..+/.test(v) || this.$t('emailmustBeValidText'),
      ],
      password: '',
      passwordRules: [
        (v) => !!v || this.$t('passwordInputHint1'),
        (v) =>
          (v && v.length >= 6) || this.$t('minimalPasswordCharacterErrortext'),
      ],

      // form essentials
      formErrors: [false],
      loading: false,
    };
  },

  computed: {
    ...mapGetters('ms_auth', {
      appMsal: 'appMsal',
    }),

    showError() {
      const error = this.$store.getters['getError'].error;
      if (!error) {
        this.formErrors.push[1];
      }
      return error;
    },
  },

  watch: {
    emailRules: 'validateForm',
    email: 'validateForm',
    password: 'validateForm',
  },

  async created() {
    this.$msalInstance = this.appMsal;
  },

  methods: {
    validateForm() {
      this.$refs.form.validate();
    },

    resetValidation() {
      this.$refs.form.valid;
    },

    //  email and password login
    async submit() {
      const data = {
        email: this.email,
        password: this.password,
      };
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('login', data)
          .then((res) => {
            this.$store.dispatch('setError', {
              text: res.data.message,
              color: 'success lighten-1',
            });
            this.loading = false;
          })
          .catch(() => {
            this.emailRules = [
              ...this.emailRules,
              (v) => v === 'invalid' || 'Invalid email or password',
            ];
            this.passwordRules = [
              ...this.passwordRules,
              (v) => v === 'invalid' || 'Invalid email or password',
            ];
          })
          .finally(() => {
            if (this.emailRules.length === 3) {
              setTimeout(() => {
                this.emailRules = this.emailRules.slice(0, 2);
                this.passwordRules = this.passwordRules.slice(0, 2);
              }, 4000);
            }
            this.loading = false;
          });
      }
    },

    // reset password
    passReset() {
      this.$router.push({ name: 'password-reset' }).catch(() => {});
    },

    // msal authentication methods.
    async adSignIn() {
      await this.$msalInstance
        .loginPopup()
        .then((response) => {
          this.$store.dispatch('ms_auth/saveAccount', response.account);
          //this.$router.push({ name: 'SuccessLogin' });
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-default-color {
  color: #536878;
}
hr.horizontal-underline {
  border: 3px solid white;
  width: 20%;
  margin-right: auto;
  margin-left: auto;
}

.mainCheckBox {
  margin-top: -1em;
}
// .v-application .text-center {
//   text-align: left;
//   margin-top: 5;
//   margin-left: 3;
// }
.center {
  text-align: center;
  padding: 180px 0;
}
</style>
